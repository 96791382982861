import React, { useEffect, useState } from "react"
import "./vertical-carousel.scss"

const VerticalCarousel = ({ data, aiPage }) => {
  const [currentPage, setCurrentPage] = useState(0)

  const onChangeCarousel = (e, index) => {
    let contentText = document.getElementById("card-content-text")
    let contentImg = document.getElementById("card-content-img")
    contentText.style.opacity = 0
    contentImg.style.opacity = 0

    setTimeout(() => {
      setCurrentPage(index)
      contentText.style.opacity = 1
      contentImg.style.opacity = 1
    }, 200)
  }

  return (
    <div class="carousel-wrapper">
      <div class="carousel-card-tabs-wrapper">
        <div class={`carousel-card-tabs ${aiPage ? "aiPageTabs" : ""}`}>
          {data.map((item, index) => (
            <div
              class={`${currentPage === index ? "fill" : ""} ${
                data[currentPage].color
              }`}
              onClick={e => onChangeCarousel(e, index)}
            >
              {item.tab}
            </div>
          ))}
        </div>
      </div>
      <div class="carousel-card-wrapper">
        <div
          id="carousel-card"
          class={`carousel-card ${data[currentPage].color}`}
        >
          {data && (
            <div class="carousel-card-content">
              <div
                id="card-content-text"
                class="carousel-card-text cssanimation sequence"
              >
                <div class="carousel-card-heading">
                  {data[currentPage].title}
                </div>
                <div class="carousel-card-desc">{data[currentPage].desc}</div>
              </div>
              <div
                id="card-content-img"
                class={`carousel-card-img cssanimation sequence ${
                  data[currentPage].rightCut ? "rightAlignedCut" : ""
                }`}
              >
                {data[currentPage].contentImg}
              </div>
            </div>
          )}
        </div>
      </div>
      {/* <div className="carousel-wrapper-mobile">
        {data &&
          data.map((item, index) => (
            <div
              key={index}
              id="carousel-card-mobile"
              class={`carousel-card ${item.color}`}
            >
              <div class="carousel-card-content">
                <div
                  id="card-content-text"
                  class="carousel-card-text cssanimation sequence"
                >
                  <div class="carousel-card-heading">{item.title}</div>
                  <div class="carousel-card-desc">{item.desc}</div>
                </div>
                <div
                  id="card-content-img"
                  class="carousel-card-img cssanimation sequence"
                >
                  <img src={item.contentImg} alt={item.title} />
                </div>
              </div>
            </div>
          ))}
      </div> */}
    </div>
  )
}

export default VerticalCarousel
